/* global FreshworksWidget */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';

import { PortalHead } from '@paytheory/components.common.portal_head';
import { NavigationDrawer } from '@paytheory/components.common.navigation_drawer';

import { AppContext } from '../../App';

import { BooksHooks } from '@paytheory/pay-theory-ui';

const AdminPortal = ({ generateMenu, paged, logout }) => {
    const { merchant } = useContext(AppContext);

    const portalHeadButtons = [
        {
          type: "action",
          icon: "question-circle",
          tooltip: "Get Help",
          action: () => {
            FreshworksWidget('open');
          }
        },
        {
          type: "action",
          icon: "sign-out",
          tooltip: "Sign Out",
          action: logout
        }
      ];

    const pageMenu = generateMenu();


    return (
        <BooksHooks.Context.Menu.Provider value={pageMenu}>
                    <BooksHooks.Context.Page.Provider value={paged}>
                        <header>
                            <PortalHead trailingButtons={portalHeadButtons} />
                        </header>
                        <nav>
                            <NavigationDrawer menuItems={pageMenu} listHead={merchant?.merchant_name} />
                        </nav>
                        <main>
                            <Outlet />
                        </main>
                    </BooksHooks.Context.Page.Provider>
        </BooksHooks.Context.Menu.Provider>
    )
};

/* eslint react/prop-types: 0 */
AdminPortal.propTypes = {
    generateMenu: PropTypes.func.isRequired,
    paged: PropTypes.object.isRequired,
    logout: PropTypes.func.isRequired
};

export default AdminPortal;
