// export const list = (offset, limit, direction, sort = [], query = [], offset_id) => {
//   let directionString = direction ? `, direction: "${direction}"` : '';
//   let offsetIdString = offset_id ? `, offsetId: "${offset_id}"` : '';
//   let offsetString = offset ? `, offset: "${offset}"` : '';
//   let queryString = `, query: "${btoa(JSON.stringify({ query_list: query, sort_list: sort }))}"`
//     return `{ settlements(limit: ${limit}${offsetString}${directionString}${queryString}${offsetIdString}) {
//                 totalRowCount,
//                 items {
//                   merchantUid,
//                   settlementBatch,
//                   settlementDate,
//                   status,
//                   transferDebitCount,
//                   transferReversalCount,
//                   transferChargebackCount,
//                   netAmount,
//                   grossAmount,
//                   totalFees,
//                   totalAdjustments,
//                   currency, }
//                } }`
//   }

import {appSyncQuery} from "./index";
import * as gql from "../constants/GraphQL";
import {Dispute, MoveDirection, QueryPair, Settlement, Settlements, SortDirection} from "../GraphQL/backoffice";
import {GraphQLResult} from "@aws-amplify/api-graphql";

const listString = `query ListSettlements($direction: MoveDirection, $limit: Int, $offset: String, $offset_id: String, $query: SqlQuery) {
  settlements(direction: $direction, limit: $limit, offset: $offset, offset_id: $offset_id, query: $query) {
    items {
      merchant_uid
      settlement_batch
      settlement_date
      net_amount
      total_fees
      total_adjustments
      gross_amount
      currency
      status
      transaction_debit_count
      transaction_dispute_count
      transaction_reversal_count
    }
    total_row_count
  }
}`

export const list = (order: SortDirection, offset: Settlement | null, limit: number, filter: QueryPair[] | null, direction: MoveDirection): Promise<GraphQLResult<{settlements: Settlements}>> => {
    let sort = [{key: 'settlement_batch', direction: order}]
    const queryObject = {
        query_list: filter,
        sort_list: sort,
    }
    const variables = {
        query: queryObject,
        offset_id: offset?.settlement_batch,
        offset: offset?.settlement_batch,
        limit: limit,
        direction: direction
    }
    return appSyncQuery(listString, variables)
}

export const getString = `query GetSettlement($query: SqlQuery) {
  settlements(query: $query) {
    items {
      merchant_uid
      settlement_batch
      settlement_date
      net_amount
      total_fees
      total_adjustments
      gross_amount
      currency
      status
      transaction_debit_count
      transaction_dispute_count
      transaction_reversal_count
    }
  }
}`

export const get = (settlementBatch: string): Promise<GraphQLResult<{settlements: Settlements}>> => {
    const queryObject = {
            query_list: [
                {
                    key: "settlement_batch",
                    value: settlementBatch,
                    operator: gql.EQUAL
                }
            ],
        sort_list: [],
    }
    const variables = {
        query: queryObject
    }
    return appSyncQuery(getString, variables)
}

export const getSettlementDisputesString = `query ListDisputes($merchant_uid: String!, $settlement_batch: Int!) {
  disputes(settlement_batch: $settlement_batch, merchant_uid: $merchant_uid) {
    account_code
    amount
    card_brand
    descriptor
    dispute_date
    dispute_id
    email
    evidence_last_send_date
    expiration_date
    full_name
    last_four
    merchant
    phone
    reason
    reason_message
    settlement_batch
    settlement_deposit_batch
    settlement_withdrawal_batch
    status
    transaction_date
    transaction_id
    updated_date
  }
}`

export const getSettlementDisputes = (merchantUid: string, settlementBatch: string): Promise<GraphQLResult<{disputes: Dispute[]}>> => {
    const variables = {
        merchant_uid: merchantUid,
        settlement_batch: settlementBatch
    }
    return appSyncQuery(getSettlementDisputesString, variables)
}