import {appSyncQuery} from "./index";
import * as gql from "../constants/GraphQL";

const listString = `query ListDevices($direction: MoveDirection = FORWARD, $limit: Int = 10, $offset: String, $offset_id: String, $query:SqlQuery) {
  devices(direction: $direction, limit: $limit, offset: $offset, offset_id: $offset_id, query: $query) {
    items {
      device_description
      device_id
      device_name
      is_active
      merchant_uid
    }
    total_row_count
  }
}`

export const list = (order, offset, limit, filter, direction) => {
    let sort = [{key: 'device_description', direction: order || gql.ASC}]
    let querylist = null
    if(filter) {
        querylist = [{
            key: 'device_description',
            value: `%${filter}%`,
            operator: 'LIKE'
        }]
    }
    const queryObject = {
        query_list: querylist,
        sort_list: sort,
    }
    const variables = {
        query: queryObject,
        offset_id: offset?.device_id,
        offset: offset?.device_description,
        limit: limit,
        direction: direction
    }
    return appSyncQuery(listString, variables)
}

const updateString = `mutation UpdateDevice($device_description: String!, $device_id: String!, $merchant_uid: String!) {
  updateDevice(device_description: $device_description, device_id: $device_id, merchant_uid: $merchant_uid)
}`

export const update = (device_description, device_id, merchant_uid) => {
    const variables = {
        device_description: device_description,
        device_id: device_id,
        merchant_uid: merchant_uid
    }
    return appSyncQuery(updateString, variables)
}