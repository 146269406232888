/* global FreshworksWidget */
import React, {createContext, lazy, Suspense, useCallback, useEffect, useState, Dispatch, SetStateAction} from 'react';

// @ts-ignore
import {ModalSpinner, NotFound} from '@paytheory/pay-theory-ui';
import {ErrorMessage, SuccessMessage} from "@paytheory/components.common.portal_head";
import { Loading } from "@paytheory/components.common.loading";

import {defaultOnboardingState} from './views/Onboarding/model';

import * as Login from './components/LoginComponents';

import './App.css';

import {Navigate, Route, Routes} from 'react-router-dom';
import AdminPortal from './components/AdminPortal';

import {withAuthenticator} from "@aws-amplify/ui-react";
// @ts-ignore
import {CognitoUserAmplify} from "aws-amplify";

import * as network from './network';

import * as ROUTES from './constants/routes';
import {MerchantOnboarding} from "./GraphQL/internal_types";
import {Merchant} from "./GraphQL/backoffice";
import {Client} from "graphql-ws";
import {generateMenu} from "./util";
import { getOnboardingStatus } from "./views/Transactions/model";
import { OnboardingState } from "./components/OnboardingCard/model";

// @ts-ignore
const Font = lazy(() => import ('@paytheory/pay-theory-ui/dist/Font'));

const Transactions = lazy(() =>
    import ('./views/Transactions'));

const Settlements = lazy(() =>
    import ('./views/Settlements'));

const SettlementDetails = lazy(() =>
    import ('./views/SettlementDetails'));

const Settings = lazy(() =>
    import ('./views/Settings'));

const Chargebacks = lazy(() =>
    import ('./views/Chargebacks'));

const AllTransactions = lazy(() =>
    import ('./views/AllTransactions'));

const Invoices = lazy(() =>
    import ('./views/Invoices'));

const Recurring = lazy(() =>
    import ('./views/Recurring'));

const RecurringDetails = lazy(() =>
    import ('./views/RecurringDetails'));

const VirtualTerminal = lazy(() =>
    import ('./views/VirtualTerminal'));

const PaymentLinks = lazy(() =>
    import ('./views/PaymentLinks'));

const Onboarding = lazy(() =>
    import ('./views/Onboarding'));

type AppContextType = {
    websocket: any;
    connected: boolean;
    signOut: () => void;
    merchantUID: string;
    merchant: Merchant | null;
    setMerchant: Dispatch<SetStateAction<Merchant | null>>;
    chargebackBadge: number | null;
    ErrorMessage: (message: any, stay?: boolean) => void;
    SuccessMessage: (message: any, stay?: boolean) => void;
    defaultParams: any;
    onboardingData: MerchantOnboarding | null;
    setOnboardingData: Dispatch<SetStateAction<MerchantOnboarding | null>>;
    getOnboardingData: (action?: (data: typeof defaultOnboardingState) => void) => void;
    onboardingStatus: OnboardingState;
}

export const AppContext = createContext<AppContextType>({} as AppContextType);

type AppProps = {
    signOut: () => void;
    user: CognitoUserAmplify;
}

export const App = (props: AppProps) => {
    const {signOut, user} = props;
    const [accessToken, setAccessToken] = useState(false);
    const [idToken, setIdToken] = useState(false);
    const [connected, setConnected] = useState(false);
    const [websocket, setWebsocket] = useState<Client | null>(null);
    const [merchantUID, setMerchantUID] = useState('');
    const [merchant, setMerchant] = useState<null | Merchant>(null);
    const [chargebackBadge, setChargebackBadge] = useState(null);
    const [defaultParams, setDefaultParams] = useState({});
    const [onboardingData, setOnboardingData] = useState<MerchantOnboarding | null>(null);
    // const [paged, setPaged] = useState<Object | null>(null);
    const typekit = process.env.REACT_APP_TYPEKIT;
    const onboardingStatus = getOnboardingStatus(merchant, onboardingData)

    useEffect(() => {
        if (user && !accessToken) {
            const signInUserSession = user.getSignInUserSession()
            let userAccessToken = signInUserSession.accessToken.jwtToken;
            let userIdToken = signInUserSession.idToken.jwtToken;
            let merchant_uid = signInUserSession.idToken.payload['custom:merchant_uid'];
            setIdToken(userIdToken)
            setAccessToken(userAccessToken)
            setMerchantUID(merchant_uid)
            getMerchant(merchant_uid)
        }
    }, [user, accessToken]);

    useEffect(() => {
        if (accessToken && idToken && connected === false) {
            let socket = network.createSocket(accessToken, idToken, setConnected)
            setWebsocket(socket)
        }
    }, [accessToken, connected, idToken]);

    useEffect(() => {
        if (merchant && (!merchant?.ach_active || !merchant?.card_active || !merchant?.cash_active)) {
            getOnboardingData()
        }
    }, [merchant]);

    const getMerchant = useCallback((uid: string) => {
        network.merchant.query(uid)
            .then(response => {
                setMerchant(response.data.merchant)
            })
            .catch(err => {
                console.log(err)
            })
    }, []);

    const getOnboardingData = useCallback((action?: (data: typeof defaultOnboardingState) => void) => {
        network.onboarding.get(merchantUID)
            .then(response => {
                const copy = JSON.parse(JSON.stringify(defaultOnboardingState))
                copy.merchant_uid = merchantUID
                const newOnboardingData = response.data?.merchantOnboarding || copy
                setOnboardingData(newOnboardingData)
                if (action) action(newOnboardingData)
            })
            .catch(err => {
                console.log(err)
            })
    }, [merchantUID, defaultOnboardingState]);

    const customSignOut = useCallback(() => {
        setAccessToken(false)
        setIdToken(false)
        setConnected(false)
        if (websocket) websocket.dispose()
        setWebsocket(null)
        signOut()
    }, [websocket, signOut]);

    const getPaymentParams = useCallback(() => {
        network.socketQuery(websocket, network.merchant.listPaymentParameters)
            .then(response => {
                const defaultPaymentParams = response.paymentParameters.find((param: any) => param.PaymentParametersType === 'default')
                setDefaultParams(defaultPaymentParams)
            })
            .catch(err => {
                console.log(err)
            })
    }, [websocket]);

    const getPendingDisputes = useCallback(() => {
        network.socketQuery(websocket, network.chargebacks.list)
            .then(response => {
                const chargebacks = response.disputes;
                let requiredAction = chargebacks.items.filter((chargeback: any) => chargeback?.status === 'inquiry' || (chargeback.evidenceLastSendDate === 0 && chargeback.status === 'pending'));
                const totalActionRequired = requiredAction.length;
                setChargebackBadge(totalActionRequired)
            })
            .catch(err => {
                console.log(err)
            })
    }, [websocket]);

    const respondToSubscription = useCallback((response: any) => {
        if (response.asyncNotification?.asyncNotificationResponse) {
            let context = response.asyncNotification.asyncNotificationResponse.contextId;
            if (context === 'DISPUTE_CHANGE') {
                getPendingDisputes()
            }
        }
    }, [getPendingDisputes]);

    const subscribeToNotifications = useCallback(() => {
        network.socketSubsciption(websocket, respondToSubscription, network.subscribe.start(['dispute_change']))
            .then(() => {
                //Response is handled in the respondToSubscription function
            })
            .catch(err => {
                console.log(err)
            })
    }, [respondToSubscription, websocket]);


    useEffect(() => {
        if (websocket && merchantUID) {
            subscribeToNotifications()
            getPendingDisputes()
            getPaymentParams()
        }
    }, [websocket, merchantUID, subscribeToNotifications, getPendingDisputes, getPaymentParams]);

    useEffect(() => {
        const minutesTillTimeout = 5;
        const timeout = minutesTillTimeout * 60000;
        const actions = ['mousemove', 'scroll', 'keydown', 'click', 'mousedown'];

        let t = setTimeout(signOut, timeout);

        const updateTimeout = () => {
            clearTimeout(t)
            t = setTimeout(signOut, timeout)
        };

        actions.forEach((action) => {
            document.addEventListener(action, updateTimeout, {
                capture: false,
                passive: true
            })
        })

        return () => {
            actions.forEach((action) => {
                document.removeEventListener(action, updateTimeout)
            })
            clearTimeout(t)
        };
    }, [signOut]);

    useEffect(() => {
        // @ts-ignore
        FreshworksWidget('hide', 'launcher');
    }, [])

    return (
        <div id="container">
            <div className="spinner-wrapper">
                <div className="modal-wrapper">
                    <AppContext.Provider value={{
                        websocket,
                        connected,
                        signOut: customSignOut,
                        merchantUID,
                        merchant,
                        setMerchant,
                        chargebackBadge,
                        ErrorMessage,
                        SuccessMessage,
                        defaultParams,
                        onboardingData,
                        setOnboardingData,
                        getOnboardingData,
                        onboardingStatus
                    }}>
                        <Suspense fallback={<ModalSpinner on/>}>
                            <Routes>
                                <Route
                                    path={ROUTES.ONBOARDING}
                                    // Show loading while we get the onboarding status and redirect if we are pending or boarded
                                    element={onboardingStatus === "loading" ? <ModalSpinner on/> :
                                        ["pending", "boarded", "need_docs"].includes(onboardingStatus) ? <Navigate replace to={ROUTES.HOME}/> : <Onboarding/>}
                                />
                                <Route path={"/"} element={<AdminPortal paged={{}}
                                                                        generateMenu={generateMenu(chargebackBadge ?? 0)}
                                                                        logout={signOut}/>}>
                                    <Route
                                        path=""
                                        element={<Navigate replace to={ROUTES.HOME}/>}
                                    />
                                    <Route
                                        path={ROUTES.HOME}
                                        element={merchant ? <Transactions/> : <Loading/>}

                                    />
                                    <Route
                                        path={ROUTES.ALL_TRANSACTIONS}
                                        element={merchant ? <AllTransactions/> : <Loading/>}
                                    />
                                    <Route
                                        path={ROUTES.INVOICES}
                                        element={merchant ? <Invoices/> : <Loading/>}
                                    />
                                    <Route
                                        path={ROUTES.SETTLEMENTS}
                                        element={merchant ? <Settlements/> : <Loading/>}
                                    />
                                    <Route
                                        path={ROUTES.SETTLEMENTS_DETAILS}
                                        element={merchant ? <SettlementDetails/> : <Loading/>}
                                    />
                                    <Route
                                        path={ROUTES.DISPUTES}
                                        element={merchant ? <Chargebacks/> : <Loading/>}
                                    />
                                    <Route
                                        path={ROUTES.MERCHANT_SETTINGS}
                                        element={merchant ? <Settings/> : <Loading/>}
                                    />
                                    <Route
                                        path={ROUTES.RECURRING}
                                        element={merchant ? <Recurring/> : <Loading/>}
                                    />
                                    <Route
                                        path={ROUTES.RECURRING_DETAILS}
                                        element={merchant ? <RecurringDetails/> : <Loading/>}
                                    />
                                    <Route
                                        path={ROUTES.VIRTUAL_TERMINAL}
                                        element={merchant ? <VirtualTerminal/> : <Loading/>}
                                    />
                                    <Route
                                        path={ROUTES.PAYMENT_LINKS}
                                        element={merchant ? <PaymentLinks/> : <Loading/>}
                                    />
                                    <Route
                                        path="*"
                                        element={<Navigate replace to={ROUTES.HOME}/>}
                                    />
                                </Route>
                            </Routes>
                            <Font typekit={typekit}/>
                        </Suspense>
                        <ModalSpinner/>
                    </AppContext.Provider>
                </div>
            </div>
        </div>
    );
};

// @ts-ignore
export default withAuthenticator(App, {
    loginMechanisms: ['email'],
    components: {
        Header: Login.Header,
        SignIn: {
            Header: Login.SignInHeader
        },
    }
});
