/* global FreshworksWidget */
import React, { StrictMode, useEffect } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Amplify from 'aws-amplify';
import * as serviceWorker from './serviceWorker'
import './styles.css'
import { BrowserRouter as Router } from 'react-router-dom'
import { GlobalStyle } from '@paytheory/components.common.global_style';


const HideFreshworks = () => {
    useEffect(()=>{
        FreshworksWidget('hide','launcher');
      },[])
    return <div />
}

const apiUrl = `https://${process.env.REACT_APP_PARTNER}.sdk.${process.env.REACT_APP_STAGE}.com/index.js`;
const scriptElement = document.createElement('script');
scriptElement.src = apiUrl;
document.head.appendChild(scriptElement);


Amplify.configure({
    Auth: {

        // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
        identityPoolId: process.env.REACT_APP_IDENTITY_POOL, //'us-east-1:ffd0e720-da44-45ab-ac61-66422d32a552',
        
        // REQUIRED - Amazon Cognito Region
        region: process.env.REACT_APP_COGNITO_REGION, //'us-east-1',

        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: process.env.REACT_APP_USER_POOL, //'us-east-1_ut0KvX8ix',

        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: process.env.REACT_APP_USER_POOL_CLIENT, //'7275mnpka5v5d0nmk1iav5de8m',

        // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
        mandatorySignIn: false
    },
    endpoints: [`https://api.${process.env.REACT_APP_PARTNER}.${process.env.REACT_APP_STAGE}.com/graphql`, `https://internal.${process.env.REACT_APP_PARTNER}.${process.env.REACT_APP_STAGE}.com/graphql`],
    aws_appsync_graphqlEndpoint: ``,
    aws_appsync_region: process.env.REACT_APP_COGNITO_REGION,
    aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
});

// const federated = {
//     amazonClientId: '3pr6e37tu9p8b9fqte5n3sbl0v' // Enter your amazonClientId here
// };


ReactDOM.render(<Router>
                    <StrictMode>
                        <GlobalStyle />
                        <HideFreshworks />
                        <App />
                    </StrictMode>
                </Router>, document.getElementById('app'));
serviceWorker.unregister();
