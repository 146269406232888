import { appSyncQuery } from './index'

const queryString = `query getMerchant($merchant_uid: String) {
  merchant(merchant_uid: $merchant_uid) {
    merchant_uid
    merchant_name
    api_key
    submitted_onboarding
    is_system
    parent_merchant_uid
    card_active
    ach_active
    cash_active
    fee_model {
      merchant_fee {
        card_basis
        card_fixed
        ach_basis
        ach_fixed
      }
      service_fee {
        card_basis
        card_fixed
        ach_basis
        ach_fixed
      }
      service_fee_min {
        card_basis
        card_fixed
        ach_basis
        ach_fixed
      }
      interchange_plus
    }
  }
}
`

// type FeeModelObject = {
//     card_basis: number
//     card_fixed: number
//     ach_basis: number
//     ach_fixed: number
//
// }
//
// export type Merchant = {
//     merchant_uid: string
//     merchant_name: string
//     api_key: string
//     submitted_boarding: boolean
//     is_system: boolean
//     parent_merchant_uid: string
//     card_active: boolean
//     ach_active: boolean
//     cash_active: boolean
//     fee_model: {
//         merchant_fee: FeeModelObject
//         service_fee: FeeModelObject
//         service_fee_min: FeeModelObject
//     }
// }

export const query = (uid)  => {
    const variables = {
        merchant_uid: uid
    }
    return appSyncQuery(queryString, variables)
}


export const listPaymentParameters = "{ paymentParameters { PaymentParametersType, PaymentParametersName, expiresIn, deniedBeforeDate, deniedAfterDate, maximumAmount, minimumAmount, maximumOccurrence, recurrencePeriod, validationLinkURL, enabled } }"
