import {appSyncQuery} from "./index";

const listString = `query ListKeys($merchant_uid: String!) {
  backOfficeKey(merchant_uid: $merchant_uid, entity_level: MERCHANT) {
    created_date
    enabled
    key_name
    last_accessed_date
    merchant_uid
  }
}

`

export const list = (merchantUid) => {
    const variables = {
        merchant_uid: merchantUid
    }
    return appSyncQuery(listString, variables, true)
}

const createString = `mutation CreateSecretKey($key_name: String, $merchant_uid: String!) {
  createBackOfficeKey(entity_level: MERCHANT, merchant_uid: $merchant_uid, key_name: $key_name) {
    api_key
    created_date
    enabled
    key_name
    last_accessed_date
    merchant_uid
  }
}
`

export const createKey = (merchant_uid, key_name) => {
    const variables = {
        merchant_uid: merchant_uid,
        key_name: key_name
    }
    return appSyncQuery(createString, variables, true)
}

const deleteString = `mutation MyMutation($key_name: String!, $merchant_uid: String!) {
  deleteBackOfficeKey(key_name: $key_name, merchant_uid: $merchant_uid)
}
`

export const deleteKey = async (merchant_uid, key_name) => {
    const variables = {
        merchant_uid: merchant_uid,
        key_name: key_name
    }
    return appSyncQuery(deleteString, variables, true)
}

const updateString = `mutation UpdateSecretKey($enabled: Boolean!, $key_name: String!, $merchant_uid: String!) {
  updateBackOfficeKey(key_name: $key_name, merchant_uid: $merchant_uid, enabled: $enabled)
}

`

export const updateKey = (merchant_uid, key_name, enabled) => {
    const variables = {
        merchant_uid: merchant_uid,
        key_name: key_name,
        enabled: enabled
    }
    return appSyncQuery(updateString, variables, true)
}